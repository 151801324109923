import React, { Component } from "react";
import CHILDPUGH from "./CHILDPUGH"

export default class GI extends Component {

    render() {
        return (
            <div class="col-md-10 offset-md-1 col-sm-12">
                <CHILDPUGH/>
                <br></br>
            </div>
        );
    }
}

import React, { Component } from "react";
import FENa from "./FENa"

export default class FENa1 extends Component {

    render() {
        return (
            <div class="col-md-10 offset-md-1 col-sm-12">
                <FENa/>
            </div>
        );
    }
}

import React, { Component } from "react";
import TTKG from "./TTKG"

export default class TTKG1 extends Component {

    render() {
        return (
            <div class="col-md-10 offset-md-1 col-sm-12">
                <TTKG/>
            </div>
        );
    }
}

import React, { Component } from "react";
import CCr from "./CCr";

export default class CCr1 extends Component {

    render() {
        return (
            <div class="col-md-10 offset-md-1 col-sm-12">
                <CCr/>
                <p>Reference:  1. Cockcroft-Gault Equation 2. MDRD quation</p>
            </div>
        );
    }
}

import React, { Component } from "react";

export default class Blog extends Component {
    render() {
        return (
            <div class="col-md-10 offset-md-1 col-sm-12">
                <br></br>
                <h2>
                    Will release soon...<br></br>
                </h2>
            </div>
        );
    }
}

import React, { Component } from "react";
import FEUN from "./FEUN"

export default class FEUN1 extends Component {

    render() {
        return (
            <div class="col-md-10 offset-md-1 col-sm-12">
                <FEUN/>
            </div>
        );
    }
}
